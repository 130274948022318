import React, { Fragment, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import { MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/hero'
import SEO from '../components/seo'
import ClientSearch from '../components/ClientSearch'
import get from 'lodash/get'
import { kebabCase } from 'lodash'

const TagsPageRoute = (props) => {
  const { pageContext } = props
  const { contentData } = pageContext
  const { allData, options } = contentData  

  const posts = get(props, 'data.allMarkdownRemark')
  const heroImage = get(props, 'data.heroImage')

  useEffect(() => {
    const handleResize = () => { 
      const searchElement = document.getElementById('search')
      if (searchElement) {
        if (searchElement.clientHeight > 140) { 
          document.getElementById('container-content').style.display = "none"
        } else {
          document.getElementById('container-content').style.display = ""
        }
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    const searchElement = document.getElementById('search')
    if (searchElement) {
      resizeObserver.observe(searchElement)
    }

    // Cleanup function to disconnect the observer (important!)
    return () => {
      if (searchElement) {
          resizeObserver.unobserve(searchElement)
      }
    }
  }, [props.content])

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-50"
          image={ heroImage.childImageSharp.gatsbyImageData }
          alt="UNICOM Engineering blog for global OEM technology updates and insights"
          title="UNICOM Engineering blog"
          subtitle="OEM updates and insights."
          type="blog"
        />        
        
        <main>
          <section id="main" className="bg-white">
            <MDBContainer id="search">
              <ClientSearch content={allData} engine={options} context="blog" tagHeader="*"/>
            </MDBContainer>
          </section>

          <section id="container-content" className="bg-white pt-0 mt-0">
            <MDBContainer>
              <div className="columns">
                <div className="column is-10 is-offset-1" style={{ marginBottom: "6rem" }} >
                  <ul className="tag-list pt-3">
                    {posts.group.map((tag, index) => (
                      <Fragment key={index}>
                        {!!tag.fieldValue &&
                          <li key={tag.fieldValue} className="py-2">
                            <Link to={`/blog/tags/${kebabCase(tag.fieldValue)}/`} className="effect">
                              {tag.fieldValue} ({tag.totalCount})
                            </Link>
                          </li>
                        }
                      </Fragment>
                    ))}
                  </ul>
                </div>
              </div>
            </MDBContainer>
          </section>
        </main>
      </div>
    </Layout>
  )
}
export default TagsPageRoute

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title={`UNICOM Engineering blog for OEM updates and insights`}
      description={`Drill down into UNICOM Engineering blogs by blog category/tag for useful worldwide OEM system integrator industry updates and insights`}
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const tagsPageQuery = graphql`
  query TagsListQuery {
    allMarkdownRemark
    {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    heroImage: file(name: { eq: "blog-hero-image" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }  
  }
`